import * as Sentry from '@sentry/vue';

export function getSentryConfig() {
  if (!(window.KOG && window.KOG.SENTRY_DSN_FRONTEND)) {
    return null;
  }

  return {
    dsn: window.KOG.SENTRY_DSN_FRONTEND,
    release: window.KOG.SENTRY_RELEASE,
    environment: window.KOG.SENTRY_ENVIRONMENT,
    maxBreadcrumbs: 50,
    allowUrls: [/kognity\.com/, /cloudfront\.net/, /kog-testing\.se/, /localhost/],
    integrations: [],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  };
}

export function identifyUser(userId) {
  Sentry.getCurrentScope().setUser({ id: userId });
}
